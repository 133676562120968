import SignaturePad from "./signature_pad"
import PinchZoom from "./pinch-zoom.umd"

var pz = undefined;

// modal
var openModal = function(modal){
    $('body').css({'overflow': 'hidden'});
    modal.data('modalOpen', 'true');
    modal.css({'display': 'block'});
};

var closeModal = function(modal, skip_overflow){
    if(typeof skip_overflow === 'undefined' || skip_overflow === false) { $('body').css({'overflow': 'auto'}) }
    modal.data('modalOpen', 'false');
    modal.css({'display': 'none'});
};

var toggleModal = function(modal){
    if(modal.data('modalOpen') === 'true'){
        closeModal(modal);
    }else{
        openModal(modal);
    }
};

// sign-btn position

var calculateSignBtnPosition = function (sign_btn) {
    var MAX_WIDTH = 264;
    var ZERO_LEFT = 465;
    var btnWidth = sign_btn.outerWidth(true);
    var newLeft = (MAX_WIDTH / 2 - btnWidth / 2) + ZERO_LEFT;
    sign_btn.css("left", newLeft);
};

// font canvas, SignaturePad

var resizeSignaturePad = function(canvas, signaturePad) {
    hideSignErrorMessage();
    if($(canvas).is(":visible") && (canvas.height !== $('#tab-2').height() || canvas.width !== $('#tab-2').width())){
        canvas.width = $('#tab-2').width();
        canvas.height = $('#tab-2').height();
        signaturePad.clear(); // otherwise isEmpty() might return incorrect value
    }
};

var resizeCanvas = function (canvas) {
    hideSignErrorMessage();
    if($(canvas).is(":visible")){
        canvas.width = $(canvas).parent().width() - 205;
        canvas.height = $(canvas).parent().height();
        changeFont(canvas,  $('.style-menu > li.current').attr('id'));
    }
};

var changeFont = function (canvas, font) {
    var name = $('#signer-name span#signer-name-text').text();
    var context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.font = "30px "+ font;
    context.fillText(name, 50, canvas.height/2);
};

var isCanvasBlank = function(canvas) {
  var context = canvas.getContext('2d');
  var data = context.getImageData(0, 0, context.canvas.width, context.canvas.height).data;
  return !Array.prototype.some.call(data, function(p){ return p > 0;});
};

// download spinner

var generateDownloadId = function(){
    return Math.random().toString(36).substr(2, 9);
};

var isDownloadComplete = function (downloadId) {
    return getCookie('download_id') === downloadId;
};

var getCookie = function(name) {
    var matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

var runDownloadSpinnerInterval = function (download_spinner, downloadId) {
    var downloadSpinnerInterval = setInterval(function() {
        if(isDownloadComplete(downloadId)){
            download_spinner.hide();
            $('body').css({'overflow': 'auto'});
            clearInterval(downloadSpinnerInterval);
        }
    }, 500);
};

var runDownloadSpinnerTimeout = function (download_spinner, downloadId) {
    setTimeout(function() {
        if(isDownloadComplete(downloadId)){
        }else{
            $('body').css({'overflow': 'hidden'});
            download_spinner.show();
            runDownloadSpinnerInterval(download_spinner, downloadId);
        }
    }, 1000);
};

// other

var scrollToSignBtn = function () {
    if(device.type == 'desktop'){
        var signBtn = $('.sign-btn.ellipsis');
        if(signBtn.length){
            setTimeout(function () {
                $('html,body').animate({scrollTop: signBtn.offset().top - 350}, 1500);
            }, 1000);
        }
    }
};

var scrollToSignBtnMobile = function () {
    setTimeout(function () {
        var signBtn = $('.sign-btn.ellipsis');

        if(signBtn.length){
            var childPos = signBtn.offset();
            var parentPos = signBtn.parent().parent().offset();
            var btnCenteredValue = 0;
            if(device.orientation == 'portrait'){
                btnCenteredValue = 300;
            }else{
                btnCenteredValue = device.type == 'mobile' ? 100 : 150;
            }

            var childTopOffset = (childPos.top - parentPos.top) - btnCenteredValue;

            $('ul.doc').animate({
                'opacity': childTopOffset
            }, {
                step: function (now, fx) {
                    $(this).css({"transform": "translate3d(0px, " + -now + "px, 0px)"});
                },
                duration: 1000,
                easing: 'linear',
                queue: false
            }, 'linear');

            pz.offset = {x: 0, y: childTopOffset};
        }
    }, 1000);
}

var trim = function (c) {
  var ctx = c.getContext('2d'),
    copy = document.createElement('canvas').getContext('2d'),
    pixels = ctx.getImageData(0, 0, c.width, c.height),
    l = pixels.data.length,
    i,
    bound = {
      top: null,
      left: null,
      right: null,
      bottom: null
    },
    x, y;

  for (i = 0; i < l; i += 4) {
    if (pixels.data[i+3] !== 0) {
      x = (i / 4) % c.width;
      y = ~~((i / 4) / c.width);

      if (bound.top === null) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x + 8;
      } else if (bound.right < x) {
        bound.right = x + 8;
      }

      if (bound.bottom === null) {
        bound.bottom = y + 8;
      } else if (bound.bottom < y) {
        bound.bottom = y + 8;
      }
    }
  }

  var trimHeight = bound.bottom - bound.top,
      trimWidth = bound.right - bound.left,
      trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

  copy.canvas.width = trimWidth;
  copy.canvas.height = trimHeight;
  copy.putImageData(trimmed, 0, 0);

  // open new window with trimmed image:
  return copy.canvas;
}

var signatureSpacePercent = function (trimmedCanvas) {
    var pixels = trimmedCanvas.getContext('2d').getImageData(0, 0, trimmedCanvas.width, trimmedCanvas.height),
    length = pixels.data.length,
    nonZeroPixelCount = 0;

    for (let i = 0; i < length; i += 1) {
        if (pixels.data[i] !== 0) {
            nonZeroPixelCount += 1;
        }
    }

    return nonZeroPixelCount * 100 / (length / 4);
}

var tabToggle = function (targetTab) {
    $(targetTab).addClass('current');
    $(targetTab).siblings().removeClass('current');
    var tab = $(targetTab).data('tab');
    $(".tabs-content > li").not(tab).css('display', 'none');
    $(tab).fadeIn();
}

var showSignErrorMessage = function (message) {
    $('#sign-modal .error-message').html(message).fadeIn(300);
}

var hideSignErrorMessage = function () {
    $('#sign-modal .error-message').fadeOut(300, function() {
        $('#sign-modal .error-message').html("");
    });
}

var initPinchZoom = function () {
    if (device.type == 'mobile' || device.type == 'tablet'){
        var el = document.querySelector('ul.doc');
        pz = new PinchZoom(el, {
            lockDragAxis: true,
            use2d: false,
            minZoom: 1,
            maxZoom: 3
        });
    }
}

var changeZoomWraperMobile = function(){
    if (device.orientation == 'portrait') {
        $('.zoom-wraper').css('height', window.innerHeight - ($('.header').height() + $('.footer').height()));
    }else{
        $('.zoom-wraper').css('height', window.innerHeight - ($('.header').height() + $('.footer').height()));
    }
}

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

var imgsLoadSync = function imgsLoadSync() {
  var state = {};
  $('ul.doc img').map(function (i, img) {
    state[i] = false;
    $(img).on('load', function () {
      state[i] = true;

      var uniq = _toConsumableArray(new Set(Object.values(state)));

      if (uniq.length == 1) {
        scrollToSignBtnMobile();
      }
    });
  });
};

var getQueryString = function() {
    var key = false, res = {}, itm = null;
    // get the query string without the ?
    var qs = location.search.substring(1);
    // check for the key as an argument
    if (arguments.length > 0 && arguments[0].length > 0){ key = arguments[0]; }
    // make a regex pattern to grab key/value
    var pattern = /([^&=]+)=([^&]*)/g;
    // loop the items in the query string, either
    // find a match to the argument, or build an object
    // with key/value pairs
    while (itm = pattern.exec(qs)) {
      if(key !== false && decodeURIComponent(itm[1]) === key){
          return decodeURIComponent(itm[2]);
      }else if (key === false){
        res[decodeURIComponent(itm[1])] = decodeURIComponent(itm[2]);
      }
    }

    return key === false ? res : null;
};

function ready() {
    var token = getQueryString('t');

    if(window.location.pathname !== '/envelope/show'){
        return;
    }
    var sign_modal = $('#sign-modal');
    var terms_modal = $('#terms-modal');
    var download_btn = $('#download');
    var download_spinner = $('#download-spinner');
    var sign_spinner = $('#sign-spinner');
    var sign_btn = $('.sign-btn');
    var fontCanvas = document.getElementById("font-sign");

    var signaturePadCanvas = document.getElementById("signature-pad");
    var signaturePad = new SignaturePad(signaturePadCanvas, {
        minWidth: 0.8,
        maxWidth: 2.8,
        throttle: 0,
        onBegin: function(){
            hideSignErrorMessage();
        }
    });

    calculateSignBtnPosition(sign_btn);

    scrollToSignBtn();

    $('.flash-notice').click(function () {
        $('.flash-notice').hide();
    });

    download_btn.click(function () {
        var downloadId = generateDownloadId();
        window.location.replace("/envelope/pdf?download_id=" + downloadId + "&t=" + token);
        runDownloadSpinnerTimeout(download_spinner, downloadId);
    });

    sign_btn.click(function(){
        openModal(sign_modal);
        resizeSignaturePad(signaturePadCanvas, signaturePad);
        resizeCanvas(fontCanvas);
    });

    $('.terms-link').click(function () {
        openModal(terms_modal);
    });

    $('#print-terms').click(function () {
        $('#terms-modal').css('min-height', '1500px');
        window.print();
        $('#terms-modal').css('min-height', '0');
    });

    $('#agree').change(function() {
        if($(this).is(':checked')){
            $('#btn-sign-submit').removeClass('disable');
        }else{
            $('#btn-sign-submit').addClass('disable');
        }
        hideSignErrorMessage();
    });

    $('#close-terms').click(function () {
        closeModal(terms_modal, $('#sign-modal').is(":visible"));
    });

    $('#sign-modal.modal-wrap, #sign-modal.modal-wrap > .modal').click(function(){
        toggleModal(sign_modal);
    });

    $('#btn-sign-submit').click(function (event) {
        if ($('#btn-sign-submit').hasClass('disable')){
            showSignErrorMessage(gon.translations.accept_terms);
            return
        }

        var data;
        if($(fontCanvas).is(":visible")){

            if (isCanvasBlank(fontCanvas)) {
                showSignErrorMessage(gon.translations.bigger_signature);
                return
            }

            trimmedCanvas = trim(fontCanvas);

            if (signatureSpacePercent(trimmedCanvas) < 5 ){
                showSignErrorMessage(gon.translations.real_signature);
                return
            }

            data = trimmedCanvas.toDataURL("image/png");
        }else{

            if (signaturePad.isEmpty()) {
                showSignErrorMessage(gon.translations.provide_sinature);
                return
            }

            var signatureCanvas = signaturePad._canvas;
            var trimmedCanvas = trim(signatureCanvas);

            var isSmallerMinSize = trimmedCanvas.height < 100 || trimmedCanvas.width < 100;
            var isSmallerPercentSize = (trimmedCanvas.width < signatureCanvas.width * 30 / 100) && // signature width < 30% of canvas and
                                   (trimmedCanvas.height < signatureCanvas.height * 50 / 100); // signature height < 50% of canvas

            if (isSmallerMinSize || isSmallerPercentSize){
                showSignErrorMessage(gon.translations.bigger_signature);
                return
            }

            if (signatureSpacePercent(trimmedCanvas) < 1 ){
                showSignErrorMessage(gon.translations.real_signature);
                return
            }

            data = trimmedCanvas.toDataURL('image/png');
        }

        $('body').css({'overflow': 'hidden'});
        sign_spinner.show();
        $('#encoded_sign').val(data);
        $('#sign-submit').click();
    });

    $('#btn-sign-modal-close').click(function () {
        closeModal(sign_modal);
    });

    $('.tabs > li').click(function (event) {
        event.preventDefault();
        tabToggle(event.target);
    });

    $('*[data-tab="#tab-2"]').click(function () {
        resizeSignaturePad(signaturePadCanvas, signaturePad);
    });

    $('*[data-tab="#tab-1"]').click(function () {
        resizeCanvas(fontCanvas);
    });

    $('#clear-canvas').click(function () {
        hideSignErrorMessage();
        signaturePad.clear();
    });

    $('.style-menu > li').click(function (event) {
        var font = $(event.target).attr('id');
        changeFont(fontCanvas, font);
        $('.style-menu > li').removeClass('current');
        $(event.target).addClass('current');
    });

    if (device.type == 'mobile' || device.type == 'tablet') {
        imgsLoadSync();

        $(window).on('orientationchange', function() {
            signaturePad.clear();
            window.setTimeout(function() {
                resizeSignaturePad(signaturePadCanvas, signaturePad);
                resizeCanvas(fontCanvas);
            }, 200);
        });

        changeZoomWraperMobile();
        initPinchZoom();

        $('body').addClass('flex');

        document.addEventListener('gesturestart', function (e) {
            e.preventDefault();
        });

        $(window).on('resize', function () {
            changeZoomWraperMobile();
        });

        tabToggle($('*[data-tab="#tab-2"]'));
    }else{
        $(window).on('resize', function() {
            resizeSignaturePad(signaturePadCanvas, signaturePad);
            resizeCanvas(fontCanvas);
        });
        resizeSignaturePad(signaturePadCanvas, signaturePad);
        tabToggle($('*[data-tab="#tab-2"]'));
    }
}

document.addEventListener("turbolinks:load", ready);
window.addEventListener("load", () => {  ready() });
