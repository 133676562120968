import 'core-js/stable'
import 'regenerator-runtime/runtime'

// common style
import '@stylesheets/application'

// console.log('Hello World from Webpacker')

import "../application/js/resources.js" // Media files

require("jquery")

import "./../application/js/signature_pad"
import "./../application/js/cookie"
import "./../application/js/current-device.min"
import "./../application/js/pinch-zoom.umd"
import "./../application/js/envelope"